import { navigate } from "gatsby"
import { logout } from "./services/keepAuth"

export const getAllUrlParams = searchString => {
  const deReturnat = {}
  const params = searchString.slice(1).split("&")
  for (var i = 0; i < params.length; i++) {
    var parameter = params[i].split("=")
    deReturnat[parameter[0]] = parameter[1]
  }
  return deReturnat
}

export const isUserCasnic = userInfo => userInfo.cuicnp.length > 10

var monthNames = [
  "IAN",
  "FEB",
  "MAR",
  "APR",
  "MAI",
  "IUN",
  "IUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
]

export const dateToDesiredFormat = date => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear() % 100}`
  return `${date.getDate()}-${
    monthNames[date.getMonth()]
  }-${date.getFullYear()}`
}

// console.log(dateToDesiredFormat(new Date()))
export function logOutUserAndNavigateHome() {
  logout()
  navigate("/")
}

export const displayNumericValue = value =>
  value.toLocaleString("ro-RO", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })

export function createObjectURL(file) {
  if (window.webkitURL) {
    return window.webkitURL.createObjectURL(file)
  } else if (window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(file)
  } else {
    return null
  }
}

export function checkIsFacturaCurenta(dataEmitere) {
  // return true
  const clonaDataEmitere =
    typeof dataEmitere === "string" ? new Date(dataEmitere) : dataEmitere
  const azi = new Date()
  azi.setDate(-2)
  return clonaDataEmitere > azi
}

export function returnDenumireLunaByValue(luna) {
  if (luna < 0) luna = luna + 12
  // console.log(luna, luna % 12);
  switch (luna % 12) {
    case 0:
      return "Ianuarie"
    case 1:
      return "Februarie"
    case 2:
      return "Martie"
    case 3:
      return "Aprilie"
    case 4:
      return "Mai"
    case 5:
      return "Iunie"
    case 6:
      return "Iulie"
    case 7:
      return "August"
    case 8:
      return "Septembrie"
    case 9:
      return "Octombrie"
    case 10:
      return "Noiembrie"
    case 11:
      return "Decembrie"
    default:
      return "Luna an"
  }
}
