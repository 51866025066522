import { PageProps, navigate } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { InfoMsgType } from "../components/InfoMsg"
import PopupLayout from "../components/Popups/PopupLayout"
import SiteMenu from "../components/siteMenu"
import { getAllUrlParams } from "../utils"
import { putActivateClientAccount } from "../utils/api/login"
import {
  saveContracts,
  saveToken,
  saveUserInfo,
} from "../utils/services/keepAuth"

export default function ActivateClientAccount({ location }: PageProps) {
  const [infoMsg, setInfoMsg] = React.useState<InfoMsgType>({
    isLoading: true,
    msg: "Încercăm activarea contului. Vă rugăm așteptați!",
  })

  React.useEffect(() => {
    const activateAccount = async (accountId: string, token: string) => {
      try {
        const apiResponse = await putActivateClientAccount({ accountId, token })
        if (apiResponse.type) {
          saveToken(apiResponse.data.token, true)
          saveUserInfo({
            _id: apiResponse.data._id,
            ...apiResponse.data.userInfo,
          })
          saveContracts(apiResponse.data.contracts)

          setInfoMsg({
            isLoading: false,
            status: "ok",
            msg: "Contul a fost activat cu succes!",
          })
          setTimeout(() => {
            navigate("/app/home/")
          }, 4700)
          // setTimeout(toggleLoginModal, 5000)
        } else
          setInfoMsg({
            isLoading: false,
            status: "error",
            msg: "Nu am putut activa contul. Linkul accesat a expirat sau contul a fost deja activat",
          })
      } catch (error) {
        console.log(error)
        setInfoMsg({
          isLoading: false,
          status: "error",
          msg: "A apărut o eroare la cerința dumneavoastră. Ori nu sunteți conectat la internet ori avem o eroare de implementare. Dacă eroarea persistă vă rugăm să contactați asistența tehnică. Vă mulțumim!",
        })
      } finally {
        // setTimeout(toggleModal, 4700)
      }
    }
    const { accountId, token } = getAllUrlParams(location.search)
    if (accountId != null && token != null) {
      activateAccount(accountId, token)
    }
  }, [location.search])
  return [
    <div key={0} id="activateClientAccountPage" className="contulMeuPage">
      {/* @ts-ignore */}
      <Helmet>
        <title>Gazmir.ro - Furnizor gaze naturale</title>
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
        <meta
          name="description"
          content="Gazmir - furnizorul tau de gaze naturale. Beneficiaza de sprijin la
              plata facturilor, semneaza contractul de furnizare! Achita facturi
              online, vezi consumul"
        />
        <meta
          name="keywords"
          content="Gazmir,Furnizor gaz,Furnizare gaz,Furnizare gaze naturale,gaze
              naturale,gaz,gn,gaz miroslava,gaz panciu,gaz odobesti,gaz
              chirnogi,Gazmir miroslava,Gazmir panciu,Gazmir odobesti,Gazmir
              chirnogi,Gazmir.ro,Contract gaz,Pret gaz,Gaz Vrancea,Gaz Iasi"
        />
      </Helmet>
      <SiteMenu location={location} showPaymentRedirectLoading={false} />
    </div>,
    <PopupLayout
      querySelectorForStopScrolling="#activateClientAccountPage"
      querySelectorForBlurredBackground="#activateClientAccountPage"
      key={1}
      titleExtraClassName="popupExtraTitle"
      popupState={{
        showPopup: true,
        type: "activateClientAccount",
        title:
          infoMsg.status != null && infoMsg.status == "ok"
            ? "Cont activat"
            : "Activare cont",
        infoMsg,
        msg2ndRow:
          infoMsg.status != null && infoMsg.status == "ok"
            ? "În câteva secunde vei fi redirecționat."
            : undefined,
      }}
    />,
  ]
}
